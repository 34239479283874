<template>
    <div class="socialfilter">
        <card type="filter default">
            <div class="row">
                <div class="col-12">
                    <div class="form-gruop" v-for="(item, index) in computedSocial" :key="index" style="display: inline-block;min-width: 100px;text-align: center;">
                        <div class="cntr">
                            <label :for="item.Red_Social" class="label-cbx">
                                <div class="icons">
                                    <span :class="'fab fa-'+item.Red_Social.toLowerCase()"></span>
                                </div>
                                <p>{{item.Red_Social}}</p>
                                <div v-if="computedPost">
                                    <p class="post-total" v-if="computedPost[item.Red_Social]">{{computedPost[item.Red_Social][0].cantidad }}</p>
                                    <p class="post-total" v-else>0</p>
                                </div>
                                <p v-else>0</p>
                                <input v-if="item.Red_Social == 'Facebook'" :name="item.Red_Social" :id="item.Red_Social" :value="socialFacebook" :checked="socialFacebook" type="checkbox" class="invisible roeuba" @input="updateValue($event.target.checked, 'Facebook')">
                                <input v-else-if="item.Red_Social == 'Instagram'" :name="item.Red_Social" :id="item.Red_Social" :value="socialInstagram" :checked="socialInstagram" type="checkbox" class="invisible" @input="updateValue($event.target.checked, 'Instagram')">
                                <input v-else :name="item.Red_Social" :id="item.Red_Social" :value="socialTwitter" :checked="socialTwitter" type="checkbox"  class="invisible" @input="updateValue($event.target.checked, 'Twitter')">
                                <div class="checkbox">
                                    <svg width="20px" height="20px" viewBox="0 0 20 20">
                                        <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
                                        <polyline points="4 11 8 15 16 6"></polyline>
                                    </svg>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>
<script>
import Card from "@/components/card/Card.component.vue";
import {mapActions, mapState} from "vuex";
export default {
    name: "Social-Filter",
    components: {
        Card,
    },
    props:{
        post: {
            post: Array,
            default: null
        },
        dashboard: {
            dashboard: Boolean,
            default: false
        },
    },
    data() {
        return {

        }
    },
    computed:{
        ...mapState(["social", "socialFacebook", "socialInstagram", "socialTwitter"]),        
        computedSocial(){
            const ref = this.social[0].socialweb;
            return ref
        },
        computedPost(){
            if(this.post != null){
                console.log(this.dashboard);
                let posts = null;
                if(this.dashboard){
                    posts = this.post
                }
                else{
                    posts = this.post.filter(x => x.Id_Competidor != 0 && x.Id_Competidor != null)
                }
                let reso = []
                posts.forEach((element,index) =>{
                const redsocial = reso.filter(x => x.Red_Social == element.Red_Social);
                if(redsocial.length == 0){
                    const count = posts.filter(x => x.Red_Social == element.Red_Social)
                    const red = element.Red_Social
                    reso[element.Red_Social] = [];
                    reso[element.Red_Social].push({
                        "cantidad": count.length,
                    });
                }
                })
                return reso
            }
            else{
                return null
            }
            
        }
    },
    created() {

    },
    methods: {
        ...mapActions(["redesWeb"]),
        async aw(data){
            var redSq = [];
            if(this.socialFacebook){
                redSq.push("Facebook");
            }
            if(this.socialInstagram){
                redSq.push("Instagram");
            }
            if(this.socialTwitter){
                redSq.push("Twitter");
            }
            var qeq = []
            for (const item of data) {
                let response = await this.axios.get(`/bextsocial/getinfocompetitors/${item.Id_Competidor}/${redSq}`)
                qeq = qeq.concat(response.data)
            }
            return qeq
        },
        async getCompetitors(idjob) {
            this.Carga();
            const id = this.social.filter(item => item.view == true)
            if(idjob != undefined){
            let finish_sync = false;
            while(!finish_sync) {
                // Check the status of the sync
                // console.log(id_empresa, idjob);
                // const pruebqwcf = await this.pruebfd(pruebaq, id_empresa)
                const sync_data1 = await this.axios.post(`/bextsocial/syncaccounts/jobstatus`, {
                id_empresa: id[0].Id_Empresa,
                id_sync: idjob,
                });
                console.log("prueba");
                // If not have any data, finish the cycle
                if(!sync_data1.data){
                finish_sync = true;
                }
                finish_sync = this.checkStatus(sync_data1.data);
            }
            console.log("termino for");
            }
            const { data } = await this.axios.get(`/bextsocial/getcompetitors/${id[0].Id_Empresa}`)
            if(data){
                const compe = await this.aw(data)
                if(compe.length != 0){
                    let arrCompetidores = [];
                    console.log(compe);
                    data.forEach(item => {
                        var countlikes = 0;
                        var countreactions = 0;
                        const post = compe.filter(element => element.Id_Competidor == item.Id_Competidor)
                        const likes = post.filter(element => element.Intg_Media_Like_count != undefined ? countlikes = countlikes + parseInt(element.Intg_Media_Like_count) : countlikes )
                        const reactions = post.filter(element => element.Tw_Retweet_Count != undefined ? countreactions = countreactions + parseInt(element.Tw_Retweet_Count) : countreactions)
                        arrCompetidores.push({
                            "ID": item.Id_Competidor,
                            "Organizacion": item.Competidor,
                            "Likes": countlikes,
                            "Post": post.length,
                            "Reacciones": countreactions,
                            "Compartidos": 0,
                            "Reiting": 0,
                            "DE": item.Id_Competidor,
                        })
                    })
                    this.fila = arrCompetidores
                    this.Carga();
                }
                else{
                    this.$hijonew({
                        group: 'competidores1',
                        type: 'danger',
                        title: "Advertencia ",
                        text: 'Suerte',
                        duration: 1000,
                        speed: 1000
                    })
                }
            }
            else{
                this.Carga();
                this.$hijonew({
                    group: 'competidores1',
                    type: 'danger',
                    title: "Advertencia ",
                    text: this.$t('competidore.error'),
                    duration: 1000,
                    speed: 1000
                })
            }
        },
        getPostRedes(){
            const {data} = this.axios.get()
        },
        updateValue(value, red){
            console.log(value, red);
            this.redesWeb({value, red})
            value = String(value);
            this.$emit('input', value);
            // value = JSON.parse(value.toLowerCase());
        }
    },
}
</script>